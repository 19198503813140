import React from 'react';
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Header from '../components/header';

export default function Template({ data }) {
    const { mdx: post } = data
    if(post !== null){
      return (
        <div>
          <Header />
            <div className="blog-post-container">
            <Helmet title={`${post.frontmatter.title}`} />
            <div className="blog-post">
              <h1>{post.frontmatter.title}</h1>
              <div
                className="blog-post-content"
              >
                <MDXRenderer>{post.body}</MDXRenderer>
                </div>
            </div>
          </div>
        </div>
      )
    }else{
      return null
    }
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        path
        title
        tags
      }
      body
    }
  }
`